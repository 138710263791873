import request from '@/utils/request'

export function getGroupInfo(formFile) {
    return request({
        url: "/api/ajax/filePreview/getGroupInfo.json",
        method: 'post',
        data: formFile,
    })
}

export function getFileInfoByGrp(formFile) {
    return request({
        url: "/api/ajax/filePreview/getFileInfoByGrp.json",
        method: 'post',
        data: formFile,
    })
}

export function getFileInfoByGrpOne(formFile) {
    return request({
        url: "/api/ajax/filePreview/getFileInfoByGrpOne.json",
        method: 'post',
        data: formFile,
    })
}

export function getClassicCase(formFile) {
    return request({
        url: "/api/ajax/filePreview/getClassicCase.json",
        method: 'post',
        data: formFile,
    })
}

export function saveRecord(formFile) {
    return request({
        url: "/api/ajax/filePreview/saveRecord.json",
        method: 'post',
        data: formFile,
    })
}